<template>
  <default-layout>
    <div class="p-4">
      <section class="modal--container auth--container create--pass-container">
        <div class="title--text">
          {{ $t('loginRegister.newPass') }}
        </div>
        <form @submit.prevent="createPass" id="createPassForm">
          <div class="auth--input" :class="{ 'has-error': validation.hasError('password') }">
            <v-text-field
              :label="$t('loginRegister.pass')"
              outlined
              :hide-details="true"
              v-model="password"
              name="password"
              class="basic--input"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showPass = !showPass)"
            ></v-text-field>
            <span class="val-error" v-if="validation.hasError('password')">{{
              validation.firstError('password')
            }}</span>
          </div>
          <div
            class="auth--input mb-1 mt-3"
            :class="{ 'has-error': validation.hasError('confirmPassword') }"
          >
            <v-text-field
              :label="$t('loginRegister.confirmPass')"
              outlined
              :hide-details="true"
              v-model="confirmPassword"
              name="confirm_password"
              class="basic--input"
              :type="showConfirmPass ? 'text' : 'password'"
              :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (showConfirmPass = !showConfirmPass)"
            ></v-text-field>
            <span class="val-error" v-if="validation.hasError('confirmPassword')">{{
              validation.firstError('confirmPassword')
            }}</span>
          </div>
          <hr class="separator" />
          <button :disabled="isLoading" type="submit" class="btn btn-primary main--btn">
            {{ $t('loginRegister.createPassBtn') }}
          </button>
        </form>
      </section>
    </div>
  </default-layout>
</template>

<script>
import { Validator } from 'simple-vue-validator';
import DefaultLayout from '@/layouts/default';

export default {
  name: 'create-password',
  components: { DefaultLayout },
  data() {
    return {
      isLoading: false,
      password: null,
      confirmPassword: null,
      showPass: false,
      showConfirmPass: false,
    };
  },
  validators: {
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(8, this.$i18n.t('errors.loginRegister.password.min', { minChar: 8 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    'confirmPassword, password': function (confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword)
          .required(this.$i18n.t('errors.loginRegister.confirmPass.required'))
          .match(password, this.$i18n.t('errors.loginRegister.confirmPass.notMatched'));
      }
    },
  },
  methods: {
    showModalSuccess() {
      this.$modal.hide('modal-forget-pass');
      this.$modal.show('modal-forget-pass-confirm');
    },
    async createPass() {
      try {
        this.isLoading = true;
        const isValid = await this.$validate();
        if (isValid) {
          // eslint-disable-next-line no-unused-vars
          await this.$store.dispatch('v2/auth/createPassword', this.password);
          await this.$swal(
            this.$i18n.t('loginRegister.resetPassHeader'),
            this.$i18n.t('loginRegister.savePassSubheader'),
            'success',
          );
          window.location.replace('/');
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
